import fetch from '../utils/fetch'

// 基础物料
export function getServiceLabelsList (query) {
  return fetch({
    url: '/goods/servicelabels',
    method: 'get',
    params: query
  })
}

export function getServiceLabelsDetail (labelId) {
  return fetch({
    url: '/goods/servicelabels/' + labelId,
    method: 'get'
  })
}

export function createServiceLabels (query) {
  return fetch({
    url: '/goods/servicelabels',
    method: 'post',
    params: query
  })
}

export function updateServiceLabels (labelId, query) {
  return fetch({
    url: '/goods/servicelabels/' + labelId,
    method: 'put',
    params: query
  })
}

export function deleteServiceLabels (labelId) {
  return fetch({
    url: '/goods/servicelabels/' + labelId,
    method: 'delete'
  })
}

// 商品类型
export function getItemsTypeList (query) {
  return fetch({
    url: '/goods/itemstype',
    method: 'get',
    params: query
  })
}

export function getItemsTypeDetail (typeId) {
  return fetch({
    url: '/goods/itemstype/' + typeId,
    method: 'get'
  })
}

export function getItemsTypeLabelsDetail (typeId) {
  return fetch({
    url: '/goods/itemstypelabels/' + typeId,
    method: 'get'
  })
}

export function createItemsType (query) {
  return fetch({
    url: '/goods/itemstype',
    method: 'post',
    params: query
  })
}

export function updateItemsType (typeId, query) {
  return fetch({
    url: '/goods/itemstype/' + typeId,
    method: 'put',
    params: query
  })
}

export function deleteItemsType (typeId) {
  return fetch({
    url: '/goods/itemstype/' + typeId,
    method: 'delete'
  })
}

// 商品
export function getItemsList (query) {
  return fetch({
    url: '/goods/items',
    method: 'get',
    params: {
      ...query,
      distributor_id: !query.distributor_id ? 0 : query.distributor_id
    }
  })
}

export function getSkuList (query) {
  return fetch({
    url: '/goods/sku',
    method: 'get',
    params: query
  })
}

export function getItemsDetail (itemId) {
  return fetch({
    url: '/goods/items/' + itemId,
    method: 'get'
  })
}

export function createItems (query) {
  return fetch({
    url: '/goods/items',
    method: 'post',
    params: query,
    refreshToken: true
  })
}

export function updateItems (itemId, query) {
  return fetch({
    url: '/goods/items/' + itemId,
    method: 'put',
    params: query,
    refreshToken: true
  })
}

export function setItemsTemplate (params) {
  return fetch({
    url: '/goods/setItemsTemplate',
    method: 'post',
    params: params
  })
}

export function setItemsSort (params) {
  return fetch({
    url: '/goods/setItemsSort',
    method: 'post',
    params: params
  })
}

export function setItemsCategory (params) {
  return fetch({
    url: '/goods/setItemsCategory',
    method: 'post',
    params: params
  })
}

export function deleteItems (itemId) {
  return fetch({
    url: '/goods/items/' + itemId,
    method: 'delete'
  })
}

// 批量设置起订量 by wyb
export function updateItemsBuycount (params) {
  return fetch({
    url: '/goods/setItemsBuycount',
    method: 'post',
    params: params
  })
}

export function getDistributionGoodsWxaCode (query) {
  return fetch({
    url: '/goods/distributionGoodsWxaCodeStream',
    method: 'get',
    params: query
  })
}

export function getItemWarningStore (query) {
  return fetch({
    url: '/goods/warning_store',
    method: 'post',
    params: query
  })
}

export function getCategory (params) {
  return fetch({
    url: '/goods/category',
    method: 'get',
    params
  })
}

export function addCategory (params) {
  return fetch({
    url: '/goods/createcategory',
    method: 'post',
    params: params
  })
}

export function editCategory (params) {
  return fetch({
    url: '/goods/category/' + params.category_id,
    method: 'put',
    params: params
  })
}

export function saveCategory (params) {
  return fetch({
    url: '/goods/category',
    method: 'post',
    params: params
  })
}

export function deleteCategory (category_id) {
  return fetch({
    url: '/goods/category/' + category_id,
    method: 'delete'
  })
}

export function updateCategory (category_id, query) {
  return fetch({
    url: '/goods/category/' + category_id,
    method: 'put',
    params: query
  })
}

export function getCategoryInfo (category_id) {
  return fetch({
    url: '/goods/category/' + category_id,
    method: 'get'
  })
}

export function getGoodsAttr (query) {
  return fetch({
    url: '/goods/attributes',
    method: 'get',
    params: query
  })
}

export function addGoodsAttr (query) {
  return fetch({
    url: '/goods/attributes',
    method: 'post',
    params: query
  })
}

export function updateGoodsAttr (attributes_id, query) {
  return fetch({
    url: '/goods/attributes/' + attributes_id,
    method: 'put',
    params: query
  })
}

export function deleteGoodsAttr (attributes_id) {
  return fetch({
    url: '/goods/attributes/' + attributes_id,
    method: 'delete'
  })
}

export function updateGoodsInfo (query) {
  return fetch({
    url: '/goods/itemsupdate',
    method: 'put',
    params: query
  })
}

export function getGoodsXyprice (id) {
  return fetch({
    url: '/goods/xypromotionsmemberprice/' + id,
    method: 'get'
  })
}

export function getGoodsPrice (id) {
  return fetch({
    url: '/goods/memberprice/' + id,
    method: 'get'
  })
}

export function getGoodsProfitPrice (id) {
  return fetch({
    url: '/goods/profit/' + id,
    method: 'get'
  })
}

export function saveGoodsProfitPrice (params) {
  return fetch({
    url: '/goods/profit/save',
    method: 'post',
    params: params
  })
}

export function saveCategoryProfitPrice (params) {
  return fetch({
    url: '/goods/category/profit/save',
    method: 'post',
    params: params
  })
}

export function updateGoodsXyprice (query) {
  return fetch({
    url: '/goods/xypromotionsmemberprice/save',
    method: 'post',
    params: query
  })
}

export function updateGoodsPrice (query) {
  return fetch({
    url: '/goods/memberprice/save',
    method: 'post',
    params: query
  })
}

export function updateItemRebateConf (query) {
  return fetch({
    url: '/goods/rebateconf',
    method: 'post',
    params: query
  })
}

export function saveTag (query) {
  return fetch({
    url: '/goods/tag',
    method: 'post',
    params: query
  })
}

export function getTagList (query) {
  return fetch({
    url: '/goods/tag',
    method: 'get',
    params: query
  })
}

export function getTagInfo (tagId) {
  return fetch({
    url: '/goods/tag/' + tagId,
    method: 'get'
  })
}

export function updateTag (query) {
  return fetch({
    url: '/goods/tag',
    method: 'put',
    params: query
  })
}

export function deleteTag (tagId) {
  return fetch({
    url: '/goods/tag/' + tagId,
    method: 'delete'
  })
}

export function itemsRelTags (query) {
  return fetch({
    url: '/goods/reltag',
    method: 'post',
    params: query
  })
}

export function tagSearchItem (query) {
  return fetch({
    url: '/goods/tagsearch',
    method: 'GET',
    params: query
  })
}

export function setHotKeywords (query) {
  return fetch({
    url: '/goods/hotkeywords',
    method: 'post',
    params: query
  })
}

export function getHotKeywords () {
  return fetch({
    url: '/goods/hotkeywords',
    method: 'get'
  })
}

export function updateItemsStore (query) {
  return fetch({
    url: '/goods/itemstoreupdate',
    method: 'put',
    params: query
  })
}

export function updateItemsErp (query) {
  return fetch({
    url: '/goods/itemerpupdate',
    method: 'put',
    params: query
  })
}

export function updateItemsStatus (query) {
  return fetch({
    url: '/goods/itemstatusupdate',
    method: 'put',
    params: query
  })
}

export function auditItems (query) {
  return fetch({
    url: '/goods/audit/items',
    method: 'put',
    params: query
  })
}

export function getGoodsExport (query) {
  return fetch({
    url: '/goods/export',
    method: 'get',
    params: query
  })
}

export function exportItemsData (data) {
  return fetch({
    url: '/goods/export',
    method: 'post',
    params: data
  })
}

export function exportItemsTagData (data) {
  return fetch({
    url: '/goods/tag/export',
    method: 'post',
    params: data
  })
}

export function syncItems () {
  return fetch({
    url: '/goods/sync/items',
    method: 'post'
  })
}

export function syncMainCategory () {
  return fetch({
    url: '/goods/sync/itemCategory',
    method: 'post'
  })
}

export function syncItemSpec () {
  return fetch({
    url: '/goods/sync/itemSpec',
    method: 'post'
  })
}

export function syncBrand () {
  return fetch({
    url: '/goods/sync/brand',
    method: 'post'
  })
}

export function saveIsGifts (query) {
  return fetch({
    url: '/goods/itemsisgiftupdate',
    method: 'put',
    params: query
  })
}

// 获取商品分享设置
export function getShareSetting (query) {
  return fetch({
    url: '/itemshare/setting',
    method: 'get',
    params: query
  })
}

// 保存商品分享设置
export function saveShareSetting (query) {
  return fetch({
    url: '/itemshare/setting',
    method: 'post',
    params: query
  })
}

// 导出小程序太阳码/h5二维码
export function exportGoodsCode (params) {
  return fetch({
    url: '/goods/code/export',
    method: 'post',
    params
  })
}

// 选品商品
export function getSelectItemsList (query) {
  return fetch({
    url: '/goods/selectItems',
    method: 'get',
    params: query
  })
}
// 确定选品商品
export function flowItems (query) {
  return fetch({
    url: '/goods/flowItems',
    method: 'post',
    params: query
  })
}

// 获取直播间通用参数配置 by wyb
export function liveroomConfig () {
  return fetch({
    url: '/distributor/liveroom/config',
    method: 'get',
  })
}
// 设置直播间通用参数配置 by wyb
export function setLiveroomConfig (query) {
  return fetch({
    url: '/distributor/liveroom/setconfig',
    method: 'post',
    params: query
  })
}
// 直播商品列表 by wyb
export function livegoods (query) {
  return fetch({
    url: '/distributor/livegoods/list',
    method: 'get',
    params: query
  })
}
// 删除直播商品 by wyb
export function deleteLivegoods (id, query) {
  return fetch({
    url: '/distributor/livegoods/drop/' + id,
    method: 'delete',
    params: query
  })
}

// 获取指定模版店的商品 by wyb
export function leishiTmplateGoods (query) {
  return fetch({
    url: '/distributor/leishitmplate/list',
    method: 'get',
    params: query
  })
}
// 获取雷士商品在每个店铺下的库存 by wyb
export function leishiStore (query) {
  return fetch({
    url: '/distributor/leishistore/list',
    method: 'get',
    params: query
  })
}
// 平台根据商品料号查询每个店铺下的库存 by wyb
export function leishiStoreNew (query) {
  return fetch({
    url: '/distributor/leishistore/newlist',
    method: 'get',
    params: query
  })
}

// 获取雷士商品在下级店铺的库存 by wyb
export function getLeishiStoreList (query) {
  return fetch({
    url: '/distributor/leishistore/distlist',
    method: 'get',
    params: query
  })
}

// 设置商品的推荐商品 by wyb
export function saveRecommendGoods (query) {
  return fetch({
    url: '/goods/recommend/goods',
    method: 'post',
    params: query
  })
}

// 获取单个商品的推荐商品信息 by wyb
export function getRecommendGoods (query) {
  return fetch({
    url: '/goods/recommend/goods',
    method: 'get',
    params: query
  })
}

// ERP库存同步
export function erpItems (query) {
  return fetch({
    url: '/goods/erpItems',
    method: 'get',
    params: query
  })
}